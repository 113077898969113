export function getDirtyValues<T extends object>(
  dirtyFields: object | boolean | undefined,
  allValues: T,
): Partial<T> {
  if (dirtyFields === true || Array.isArray(dirtyFields)) return allValues
  return Object.fromEntries(
    Object.keys(dirtyFields as object).map((key) => [
      key,
      getDirtyValues(
        (dirtyFields as Record<string, object | boolean | undefined>)[key],
        (allValues as Record<string, unknown>)[key] as object,
      ),
    ]),
  ) as Partial<T>
}
