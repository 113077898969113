import {
  LazyQueryExecFunction,
  MutationFunction,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client'
import { enqueueSnackbar } from 'notistack'
import { createContext, ReactNode, useEffect, useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useParams, useSearchParams } from 'react-router'
import {
  AddReportDocument,
  AddReportJobDocument,
  AddReportMutation,
  DeleteReportDocument,
  DeleteReportMutation,
  Report,
  ReportDateMode,
  ReportDocument,
  ReportInput,
  ReportJobLangidEnum,
  ReportJobsDocument,
  ReportJobsQuery,
  ReportJobState,
  ReportResults,
  ReportResultsDocument,
  ReportResultsQuery,
  ReportsAllDocument,
  ReportsQuickDocument,
} from '../../generated/graphql'
import { useFormFilter } from '../../hooks/form-filter'
import { useMe } from '../../hooks/me'
import { getErrorMessages } from '../../utils/error-mapping'
import { stripNullValues } from '../../utils/strip-null-values'
import { ArrayElement } from '../../utils/types'
import { calculateDateSpan } from './calculate-date-span'
import { useDayJs } from '../../hooks/day-js'

export type ReportsContextType = {
  onAddReportJob: (report: Report) => Promise<void>
  isAddingReportJob: boolean
  report?: Report
  isLoadingReport: boolean
  fetchReportResults?: LazyQueryExecFunction<
    ReportResultsQuery,
    {
      jobId: number
    }
  >
  reportResults?: ReportResults
  isLoadingReportResults: boolean
  reportJobs?: ArrayElement<ReportJobsQuery['reportJobs']['items']>[]
  isLoadingReportJobs: boolean
  reportJob?: ArrayElement<ReportJobsQuery['reportJobs']['items']>
  addReport: MutationFunction<AddReportMutation, { report: ReportInput }>
  deleteReport: MutationFunction<DeleteReportMutation, { reportId: number }>
  reportJobFormContext: UseFormReturn<ReportJobFormContext>
}

export type ReportJobFormContext = {
  reportJobId?: string
}

export const ReportsContext = createContext<ReportsContextType | undefined>(
  undefined,
)

export const ReportsProvider = ({ children }: { children: ReactNode }) => {
  const { id } = useParams()
  const [searchParams] = useSearchParams()
  const reportId = Number(id)
  const reportJobId = searchParams.get('reportJobId')
  const dayJs = useDayJs()

  const { me } = useMe()

  const { formContext, applyFilter } = useFormFilter<ReportJobFormContext>({
    formProps: {
      defaultValues: { reportJobId: reportJobId || '' },
    },
  })

  const { data: reportData, loading: isLoadingReport } = useQuery(
    ReportDocument,
    {
      variables: {
        reportId,
      },
    },
  )

  const {
    data: reportJobs,
    loading: isLoadingReportJobs,
    startPolling: startPollingJobs,
    stopPolling: stopPollingJobs,
  } = useQuery(ReportJobsDocument, {
    variables: {
      reportId,
    },
  })

  const [
    fetchReportResults,
    { data: reportResults, loading: isLoadingReportResults },
  ] = useLazyQuery(ReportResultsDocument, {
    variables: { jobId: Number(reportJobId) },
  })

  const [addReportJobMutation, { loading: isAddingReportJob }] = useMutation(
    AddReportJobDocument,
    {
      refetchQueries: [
        {
          query: ReportJobsDocument,
          variables: { reportId: reportId },
        },
      ],
    },
  )

  const [addReport] = useMutation(AddReportDocument, {
    refetchQueries: [ReportsAllDocument, ReportsQuickDocument],
  })
  const [deleteReport] = useMutation(DeleteReportDocument, {
    refetchQueries: [ReportsAllDocument, ReportsQuickDocument],
  })

  const resolveDateSpan = useMemo(
    () =>
      ({ datemode, reportdefinition: { filters } }: Report) => {
        if (datemode === ReportDateMode.Default || !datemode) {
          const { orderDateStart, orderDateEnd } = filters
          return [orderDateStart ?? undefined, orderDateEnd ?? undefined]
        }
        return calculateDateSpan(datemode, dayJs())
      },
    [dayJs],
  )

  const onAddReportJob = async (report: Report) => {
    const [orderDateStart, orderDateEnd] = resolveDateSpan(report)
    const reportDefinitionWithDates = {
      ...report.reportdefinition,
      filters: {
        ...report.reportdefinition.filters,
        orderDateStart,
        orderDateEnd,
      },
    }

    try {
      const newReportJob = await addReportJobMutation({
        variables: {
          reportJob: {
            reportid: report.reportid,
            reporttype: report.reporttype,
            reportdefinition: stripNullValues(reportDefinitionWithDates),
            langid: ReportJobLangidEnum.En,
            timezone: me?.timeZone || 'Europe/Stockholm', // TODO: remove default value or move to utils/constants
            jobname: `${report.reportname} - ${new Date().toLocaleString()}`,
          },
        },
      })
      const newReportJobId = newReportJob.data?.addReportJob?.jobid
      if (newReportJobId) {
        formContext.setValue('reportJobId', String(newReportJobId))
        void applyFilter()
      }
    } catch (error) {
      const errorMessages = getErrorMessages(error)
      enqueueSnackbar({ message: errorMessages[0], variant: 'error' })
    }
  }

  const shouldPollReportJobs = useMemo(() => {
    if (!reportJobs?.reportJobs.items) return false
    return reportJobs?.reportJobs.items.some((job) =>
      [ReportJobState.InProgress, ReportJobState.Pending].includes(
        job.jobstate,
      ),
    )
  }, [reportJobs?.reportJobs.items])

  const reportJob = useMemo(
    () =>
      reportJobs?.reportJobs.items?.find(
        (job) => job.jobid === Number(reportJobId),
      ),
    [reportJobId, reportJobs?.reportJobs.items],
  )

  useEffect(() => {
    if (shouldPollReportJobs) {
      startPollingJobs(3000)
    } else {
      stopPollingJobs()
    }
  }, [shouldPollReportJobs, startPollingJobs, stopPollingJobs])

  useEffect(() => {
    if (reportJob?.jobstate === ReportJobState.Finished) {
      void fetchReportResults({ variables: { jobId: Number(reportJobId) } })
    }
  }, [fetchReportResults, reportJob, reportJobId])

  return (
    <ReportsContext.Provider
      value={{
        reportJobFormContext: formContext,
        reportJob: reportJob,
        reportJobs: reportJobs?.reportJobs.items || [],
        isLoadingReportJobs,
        fetchReportResults,
        reportResults: reportResults?.reportResults,
        isLoadingReportResults,
        report: reportData?.report,
        isLoadingReport,
        onAddReportJob,
        isAddingReportJob,
        addReport,
        deleteReport,
      }}
    >
      {children}
    </ReportsContext.Provider>
  )
}
