import i18next from 'i18next'

type Options = {
  locale?: string
  decimals?: number
}

/**
 * Converts a number to a decimal with a fixed point
 *
 * @example
 *   formatDecimal(0) => "0"
 *   formatDecimal(1) => "1"
 *   formatDecimal(1.0) => "1"
 *   formatDecimal(1.1) => "1.100"
 *   formatDecimal(1.12345) => "1.123"
 *   formatValue('abc') => undefined
 */
export const formatDecimal = (
  decimal: string | number | undefined | null,
  options?: Options,
): string | undefined => {
  const locale = options?.locale ?? i18next.language
  const decimals = options?.decimals ?? 3

  if (decimal === null || decimal === undefined) {
    return undefined
  }

  if (typeof decimal === 'string') {
    decimal = decimal.replaceAll(',', '.').trim()
  }

  const number = Number(decimal)

  if (Number.isNaN(number) || !Number.isFinite(number) || decimal === '') {
    return undefined
  }

  if (Number.isInteger(number)) {
    return String(number)
  }

  const formatOptions: Intl.NumberFormatOptions = {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }

  return new Intl.NumberFormat(locale, formatOptions).format(number)
}
