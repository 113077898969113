/* eslint-disable @typescript-eslint/no-misused-promises */
import { useMutation, useQuery } from '@apollo/client'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid2 as Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@mui/material'
import { CloseIcon, SectionHeader, Toggle } from '@sitoo/mui-components'
import { enqueueSnackbar } from 'notistack'
import { useEffect, useMemo } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FilterViewPanelFooter } from '../../../components/filter-view-panel/filter-view-panel-footer'
import { ViewPanel } from '../../../components/view-panel'
import {
  OrderPaymentState,
  Report,
  ReportDateMode,
  ReportDefinitionFilterKey,
  ReportExtendedDocument,
  ReportGroupingProperty,
  ReportMeasureProperty,
  ReportOrderState,
  UpdateReportDocument,
} from '../../../generated/graphql'
import { useTracking } from '../../../hooks/tracking'
import { AutocompleteInput } from '../../../inputs/autocomplete-input'
import { DatePickerInput } from '../../../inputs/date-picker-input'
import { Option, SelectInput } from '../../../inputs/select-input'
import { TextFieldInput } from '../../../inputs/text-field-input'
import { tryParseEnum, tryParseEnumList } from '../../../utils/enum'
import { getErrorMessages } from '../../../utils/error-mapping'
import { stripNullValues } from '../../../utils/strip-null-values'

type Props = {
  onClose(): void
  isOpen: boolean
  reportId: number
}

type FormattedOrderStateKey =
  | keyof typeof ReportOrderState
  | `${keyof typeof ReportOrderState}:${keyof typeof ReportOrderState}`

type Form = {
  dateMode: ReportDateMode
  forceWarehouseFilter: boolean
  groups: ReportGroupingProperty[]
  measures: ReportMeasureProperty[]
  orderDateEnd: string
  orderDateStart: string
  orderHasRegisterId: `${true | false | null}`
  orderIsNegative: `${true | false | null}`
  orderPaymentState: OrderPaymentState | `${null}`
  orderRegisterId: string
  orderSearchText: string
  orderState: FormattedOrderStateKey
  orderVoucherCode: string
  orderVoucherName: string
  orderWarehouseId: string
  reportName: string
  sort: string
}

const formatSortOrder = ({ key, asc }: { key: string; asc: boolean }) =>
  `${key}:${asc ? 'asc' : 'desc'}`

const parseFormattedSortOrder = (formattedSort: string) => {
  const [key, direction] = formattedSort.split(':')
  const keyAsEnum = tryParseEnum(ReportDefinitionFilterKey, key ?? '')
  if (!keyAsEnum || !direction) {
    return undefined
  }

  return {
    key: keyAsEnum,
    asc: direction === 'asc',
  }
}

export const EditReportViewPanel = ({ onClose, reportId, isOpen }: Props) => {
  const { t } = useTranslation(['reports', 'shared'])
  const { trackFormSuccess, trackFormError, trackButtonClick } = useTracking()

  const { data } = useQuery(ReportExtendedDocument, {
    variables: {
      reportId,
    },
  })

  const [updateReportMutation] = useMutation<Report>(UpdateReportDocument, {
    refetchQueries: [ReportExtendedDocument],
  })

  const { handleSubmit, control, reset, register } = useForm<Form>({
    defaultValues: {
      dateMode: ReportDateMode.Default,
      forceWarehouseFilter: true,
      groups: [],
      measures: [],
      orderDateStart: '',
      orderDateEnd: '',
      orderHasRegisterId: `${null}`,
      orderIsNegative: `${null}`,
      orderPaymentState: `${null}`,
      orderRegisterId: `${null}`,
      orderSearchText: '',
      orderState: 'Open:Closed',
      orderVoucherCode: '',
      orderVoucherName: '',
      orderWarehouseId: `${null}`,
      reportName: '',
      sort: `${null}`,
    },
  })

  const dateModeOptions: Option<Form['dateMode']>[] = Object.values(
    ReportDateMode,
  ).map((value) => ({
    name: t(`date_mode_keys.${value}`),
    value,
  }))

  const orderStateOptions: Option<Form['orderState']>[] = [
    {
      name: t('order_states.open_closed'),
      value: 'Open:Closed',
    },
    {
      name: t('order_states.open'),
      value: 'Open',
    },
    {
      name: t('order_states.closed'),
      value: 'Closed',
    },
    {
      name: t('order_states.cancelled'),
      value: 'Cancelled',
    },
  ]

  const orderPaymentStateOptions: Option<Form['orderPaymentState']>[] = [
    {
      name: t('edit.include_all'),
      value: `${null}`,
    },
    ...Object.values(OrderPaymentState).map((value) => ({
      name: t(`payment_state_keys.${value}`),
      value,
    })),
  ]

  const orderIsNegativeOptions: Option<Form['orderIsNegative']>[] = [
    {
      name: t('edit.include_all'),
      value: `${null}`,
    },
    { name: t('edit.sales_only'), value: `${false}` },
    { name: t('edit.refund_only'), value: `${true}` },
  ]

  const orderHasRegisterIdOptions: Option<Form['orderHasRegisterId']>[] = [
    {
      name: t('edit.include_all'),
      value: `${null}`,
    },
    { name: t('edit.pos'), value: `${true}` },
    { name: t('edit.webshop'), value: `${false}` },
  ]

  const orderRegisterIdOptions: Option<Form['orderRegisterId']>[] = [
    {
      name: t('edit.include_all'),
      value: `${null}`,
    },
    ...(data?.allCashRegisters?.map((cashRegister) => ({
      name: t('edit.pos_list_item', {
        registerNumber: cashRegister?.registernumber ?? '',
        registerkey: cashRegister?.registerkey ?? '',
      }),
      value: cashRegister?.registerid?.toString() ?? '',
    })) ?? []),
  ]

  const orderWarehouseIdOptions: Option<Form['orderWarehouseId']>[] = [
    {
      name: t('edit.include_all'),
      value: `${null}`,
    },
    ...(data?.allWarehouses?.map((warehouse) => ({
      name: warehouse.name,
      value: warehouse.id.toString(),
    })) ?? []),
  ]

  const sortOptions: Option<Form['sort']>[] = [
    { name: t('edit.default'), value: `${null}` },
    ...useWatch({
      control,
      name: ['groups', 'measures'],
    })
      .flat()
      .flatMap((property) => {
        const translation = t(`report_keys.${property}`)
        return [
          {
            name: t(`edit.sort_asc`, { key: translation }),
            value: formatSortOrder({ key: property, asc: true }),
          },
          {
            name: t(`edit.sort_desc`, { key: translation }),
            value: formatSortOrder({ key: property, asc: false }),
          },
        ]
      }),
  ]

  useEffect(() => {
    if (!data) return
    const {
      report: {
        reportname,
        datemode,
        forcewarehousefilter,
        reportdefinition: {
          filters,
          measures,
          groups,
          sort: [firstSortOrder],
        },
      },
    } = data
    const [firstOrderState, secondOrderState] = filters.orderState ?? []
    reset({
      reportName: reportname,
      dateMode: datemode ?? ReportDateMode.Default,
      forceWarehouseFilter: forcewarehousefilter ?? true,
      groups,
      measures,
      orderDateEnd: filters.orderDateEnd ?? '',
      orderDateStart: filters.orderDateStart ?? '',
      orderHasRegisterId: `${filters.orderHasRegisterId ?? null}`,
      orderIsNegative: `${filters.orderIsNegative ?? null}`,
      orderPaymentState: filters.orderPaymentState ?? `${null}`,
      orderSearchText: filters.orderSearchText ?? '',
      orderVoucherCode: filters.orderVoucherCode ?? '',
      orderVoucherName: filters.orderVoucherName ?? '',
      orderRegisterId: filters.orderRegisterId ?? `${null}`,
      orderWarehouseId: filters.orderWarehouseId?.toString() ?? `${null}`,
      orderState:
        firstOrderState && secondOrderState
          ? `${firstOrderState}:${secondOrderState}`
          : (firstOrderState ?? undefined),
      sort: firstSortOrder
        ? formatSortOrder({ key: firstSortOrder.key, asc: firstSortOrder.asc })
        : `${null}`,
    })
  }, [data, reset, t])

  const onSubmit = (formData: Form): void => {
    trackButtonClick({
      name: 'report-save',
      reportId,
    })

    if (!data) {
      return
    }
    const sort = parseFormattedSortOrder(formData.sort)

    const report: Report = {
      ...data.report,
      reportname: formData.reportName,
      datemode: formData.dateMode,
      forcewarehousefilter: formData.forceWarehouseFilter,
      reportdefinition: {
        ...data.report.reportdefinition,
        groups: formData.groups,
        measures: formData.measures,
        sort: sort ? [sort] : [],
        filters: {
          ...data.report.reportdefinition.filters,
          orderSearchText: formData.orderSearchText,
          orderDateStart: formData.orderDateStart,
          orderDateEnd: formData.orderDateEnd,
          orderVoucherName: formData.orderVoucherName,
          orderVoucherCode: formData.orderVoucherCode,
          orderIsNegative: JSON.parse(formData.orderIsNegative),
          orderHasRegisterId: JSON.parse(formData.orderHasRegisterId),
          orderPaymentState:
            formData.orderPaymentState === (`${null}` as const)
              ? null
              : formData.orderPaymentState,
          orderState: tryParseEnumList(
            ReportOrderState,
            formData.orderState.split(':'),
          ),
          orderWarehouseId:
            formData.orderWarehouseId === `${null}`
              ? null
              : parseInt(formData.orderWarehouseId),
          orderRegisterId:
            formData.orderRegisterId === `${null}`
              ? null
              : formData.orderRegisterId,
        },
      },
    }

    updateReportMutation({
      variables: {
        report: stripNullValues(report, {
          deleteEmptyStrings: true,
        }),
      },
    })
      .then(() => {
        enqueueSnackbar(t('status_messages.update_success'), {
          variant: 'success',
        })
        trackFormSuccess({
          name: 'report',
        })
      })
      .catch((error) => {
        const [firstErrorMessage] = getErrorMessages(error)
        enqueueSnackbar(firstErrorMessage, {
          variant: 'error',
        })
        trackFormError({
          name: 'report',
          errorMessage: firstErrorMessage,
        })
      })
  }

  const onInvalid = () => {
    enqueueSnackbar(t('status_messages.invalid_form'), {
      variant: 'error',
    })
  }

  const dateMode = useWatch({
    control,
    name: 'dateMode',
  })

  const filteredMeasureKeys = useMemo(
    () =>
      Object.keys(ReportMeasureProperty).filter(
        (measure) =>
          measure.startsWith(`Measure${data?.report.reporttype}`) &&
          !measure.startsWith('MeasureOmniFulfillment'),
      ),
    [data?.report.reporttype],
  )

  const filteredGroupKeys = useMemo(
    () =>
      Object.keys(ReportGroupingProperty).filter((group) =>
        group.startsWith(`Group${data?.report.reporttype}`),
      ),
    [data?.report.reporttype],
  )

  return (
    <ViewPanel open={isOpen}>
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 10,
          background: (theme) => theme.palette.background.paper,
          padding: (theme) => theme.spacing(1, 1, 1, 2),
          borderBottom: (theme) => `1px solid ${theme.palette.gray10}`,
        }}
      >
        <Grid container>
          <Grid size="grow" sx={{ display: 'flex', alignItems: 'center' }}>
            <SectionHeader sx={{ padding: 0, backgroundColor: 'transparent' }}>
              {t('edit.report_configuration')}
            </SectionHeader>
          </Grid>
          <Grid>
            <IconButton onClick={onClose}>
              <CloseIcon fontSize="medium" />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <form>
        <Accordion defaultExpanded className="MuiAccordionRoot">
          <AccordionDetails sx={{ px: 0, py: 1.5 }}>
            <List>
              <ListItem>
                <TextFieldInput
                  label={t('edit.name')}
                  name="reportName"
                  control={control}
                  required
                />
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded className="MuiAccordionRoot">
          <AccordionSummary aria-controls="filter">
            <SectionHeader sx={{ p: 0 }}>
              {t('edit.properties_grouping_variables')}
            </SectionHeader>
          </AccordionSummary>
          <AccordionDetails sx={{ px: 0, py: 1.5 }}>
            <List>
              <ListItem>
                <AutocompleteInput
                  name="groups"
                  control={control}
                  multiple
                  options={filteredGroupKeys}
                  autocompleteProps={{
                    getOptionLabel: (value) => t(`report_keys.${value}`),
                  }}
                  required
                />
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded className="MuiAccordionRoot">
          <AccordionSummary aria-controls="filter">
            <SectionHeader sx={{ p: 0 }}>{t('edit.measures')}</SectionHeader>
          </AccordionSummary>
          <AccordionDetails sx={{ px: 0, py: 1.5 }}>
            <List>
              <ListItem>
                <AutocompleteInput
                  name="measures"
                  control={control}
                  multiple
                  options={filteredMeasureKeys}
                  autocompleteProps={{
                    getOptionLabel: (value) => t(`report_keys.${value}`),
                  }}
                  required
                />
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded className="MuiAccordionRoot">
          <AccordionSummary aria-controls="filter">
            <SectionHeader sx={{ p: 0 }}>
              {t('edit.sorting_filtering')}
            </SectionHeader>
          </AccordionSummary>
          <AccordionDetails sx={{ px: 0, pt: 1.5, pb: 0 }}>
            <List>
              <ListItem>
                <SelectInput
                  control={control}
                  label={t('edit.sort_order')}
                  name="sort"
                  options={sortOptions}
                />
              </ListItem>
              <ListItem>
                <TextFieldInput
                  control={control}
                  label={t('edit.search_text')}
                  name="orderSearchText"
                />
              </ListItem>
              <ListItem>
                <SelectInput
                  control={control}
                  label={t('edit.date_filter')}
                  name="dateMode"
                  options={dateModeOptions}
                />
              </ListItem>
              {dateMode === ReportDateMode.Default && (
                <>
                  <ListItem>
                    <DatePickerInput
                      control={control}
                      label={t('edit.order_date_from')}
                      name="orderDateStart"
                    />
                  </ListItem>
                  <ListItem>
                    <DatePickerInput
                      control={control}
                      label={t('edit.order_date_to')}
                      name="orderDateEnd"
                    />
                  </ListItem>
                </>
              )}
              <ListItem>
                <SelectInput
                  control={control}
                  label={t('edit.order_state')}
                  name="orderState"
                  options={orderStateOptions}
                />
              </ListItem>
              <ListItem>
                <SelectInput
                  control={control}
                  label={t('edit.payment_state')}
                  name="orderPaymentState"
                  options={orderPaymentStateOptions}
                />
              </ListItem>
              <ListItem>
                <SelectInput
                  control={control}
                  label={t('edit.purchase_type')}
                  name="orderIsNegative"
                  options={orderIsNegativeOptions}
                />
              </ListItem>
              <ListItem>
                <SelectInput
                  control={control}
                  label={t('edit.sales_channel')}
                  name="orderHasRegisterId"
                  options={orderHasRegisterIdOptions}
                />
              </ListItem>
              <ListItem>
                <SelectInput
                  control={control}
                  label={t('edit.pos')}
                  name="orderRegisterId"
                  options={orderRegisterIdOptions}
                />
              </ListItem>
              <ListItem>
                <SelectInput
                  control={control}
                  label={t('edit.warehouse')}
                  name="orderWarehouseId"
                  options={orderWarehouseIdOptions}
                />
              </ListItem>
              <ListItem>
                <TextFieldInput
                  control={control}
                  label={t('edit.campaign_name')}
                  name="orderVoucherName"
                />
              </ListItem>
              <ListItem>
                <TextFieldInput
                  control={control}
                  label={t('edit.campaign_code')}
                  name="orderVoucherCode"
                />
              </ListItem>
              <ListItem
                secondaryAction={
                  <Toggle {...register('forceWarehouseFilter')} />
                }
              >
                <ListItemText
                  primary={t('edit.store_manager_warehouse_filtering')}
                />
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>

        <FilterViewPanelFooter>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
              gap: (theme) => theme.spacing(2),
            }}
          >
            <Button size="small" onClick={handleSubmit(onSubmit, onInvalid)}>
              {t('shared:action.save')}
            </Button>
          </Box>
        </FilterViewPanelFooter>
      </form>
    </ViewPanel>
  )
}
