import { InputLabel, List, ListItem, ListItemText, Paper } from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { AutocompleteInput } from '../../../../inputs/autocomplete-input'
import { currencies, getCurrencyName } from '../../../../i18n/currencies'
import { useFormContext } from 'react-hook-form'
import { GeneralSettingsFormContext } from '../general-settings-form'

const timeZones = Intl.supportedValuesOf('timeZone')

export const CurrencyAndTimezone = () => {
  const { t } = useTranslation(['shared', 'settings'])
  const { control } = useFormContext<GeneralSettingsFormContext>()

  return (
    <Paper elevation={0} sx={{ mb: 2 }}>
      <SectionHeader sx={{ px: 2, position: 'relative' }} variant="transparent">
        {t('settings:language_and_timezone')}
      </SectionHeader>

      <List>
        <ListItem>
          <ListItemText>
            <InputLabel>{t('settings:general.currency')}</InputLabel>

            <AutocompleteInput
              name="currencycode"
              control={control}
              options={currencies}
              autocompleteProps={{
                limitTags: 5,
                getOptionKey: (option) => option,
                getOptionLabel: (option) =>
                  `${option} - ${getCurrencyName(option)}`,
              }}
              required
            />
          </ListItemText>
        </ListItem>

        <ListItem>
          <ListItemText>
            <InputLabel>{t('settings:general.time_zone')}</InputLabel>

            <AutocompleteInput
              name="timezone"
              control={control}
              options={timeZones}
              autocompleteProps={{
                limitTags: 5,
                getOptionKey: (option) => option,
              }}
              textFieldProps={{
                helperText: t('settings:general.timezone_helper_text'),
              }}
              required
            />
          </ListItemText>
        </ListItem>
      </List>
    </Paper>
  )
}
