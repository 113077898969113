/**
 * Recursively replace null object fields to undefined
 */
export const stripNullValues = <T extends object>(
  args: T,
  opts?: {
    deleteEmptyProperties?: boolean
    deleteEmptyStrings?: boolean
  },
): T => {
  const result = {} as Record<string, unknown>

  Object.entries(args).forEach(([key, value]) => {
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      const objectValue = stripNullValues(value, opts) as object
      if (
        opts?.deleteEmptyProperties &&
        !Object.values(objectValue).some((x) => Boolean(x))
      ) {
        delete result[key]
      } else {
        result[key] = objectValue
      }
    } else if (value === '' && opts?.deleteEmptyStrings) {
      delete result[key]
    } else {
      result[key] = value === null ? undefined : value
    }
  })

  return result as T
}
