import i18next from 'i18next'

export const currencies = Intl.supportedValuesOf('currency')

export const getCurrencyName = (currencyCode: string) => {
  const language = i18next.language

  const displayNames = new Intl.DisplayNames([language], { type: 'currency' })
  const currencyName = displayNames.of(currencyCode)

  return currencyName || ''
}

export const getSupportedCurrencies = () => {
  return currencies.map((currency) => ({
    label: getCurrencyName(currency),
    value: currency,
  }))
}
