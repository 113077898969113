import { Box, capitalize, Divider, Paper } from '@mui/material'
import { GridColDef, GridPinnedRowsProp } from '@mui/x-data-grid-pro'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DataGrid } from '../../components/data-grid'
import {
  ReportJobsQuery,
  ReportResultColumnsInner,
  ReportResultItemValueDisplayType,
  ReportResults,
} from '../../generated/graphql'
import { useLocalizedDate } from '../../hooks/localized-date'
import { useMoney } from '../../hooks/money'
import { formatDecimal } from '../../utils/format/number'
import { ArrayElement } from '../../utils/types'

type Props = {
  reportResults?: ReportResults
  reportJob?: ArrayElement<ReportJobsQuery['reportJobs']['items']>
  reportName?: string
}
type Row = Record<string, unknown>

export const TableSection = (props: Props) => {
  const { t } = useTranslation(['reports'])
  const { formatCurrency } = useMoney()
  const { formatDate } = useLocalizedDate()

  const formatTableValue = useCallback(
    (
      value: string | number,
      type?: ReportResultItemValueDisplayType | null,
    ) => {
      if (value == null) return '-'
      if (!type) return value

      if (typeof value === 'string') {
        switch (type) {
          case ReportResultItemValueDisplayType.TimestampYear:
            return formatDate(value, { year: 'numeric' })
          case ReportResultItemValueDisplayType.TimestampYearMonth:
            return formatDate(value, {
              year: 'numeric',
              month: 'long',
            })
          case ReportResultItemValueDisplayType.TimestampYearMonthDay:
            return formatDate(value, {
              year: 'numeric',
              month: 'long',
              day: '2-digit',
            })
          case ReportResultItemValueDisplayType.Money:
            return formatCurrency(value, {
              forceDecimals: true,
            })
          case ReportResultItemValueDisplayType.MoneyFixed: {
            return formatDecimal(value, { decimals: 2 })
          }
          case ReportResultItemValueDisplayType.Decimalquantity:
            return formatDecimal(value)
          default:
            return value
        }
      }
      if (typeof value === 'number') {
        switch (type) {
          case ReportResultItemValueDisplayType.IntegerYearWeek: {
            const valueAsString = String(value)
            const year = valueAsString.substring(0, 4)
            const week = valueAsString.substring(4)
            return t('reports:formats.integer_year_week', { year, week })
          }
          case ReportResultItemValueDisplayType.Double:
            return formatDecimal(value, { decimals: 2 })
          case ReportResultItemValueDisplayType.DoublePercentage:
            return `${formatDecimal(value * 100, { decimals: 1 })}%`
          default:
            return value
        }
      }

      return value
    },
    [formatCurrency, formatDate, t],
  )

  const columnMapper = useCallback(
    (column: ReportResultColumnsInner): GridColDef<Row> => {
      if (!column.key) return { field: '', flex: 1, headerName: '' }

      const isNumeric = [
        ReportResultItemValueDisplayType.Money,
        ReportResultItemValueDisplayType.MoneyFixed,
        ReportResultItemValueDisplayType.Decimalquantity,
        ReportResultItemValueDisplayType.Double,
        ReportResultItemValueDisplayType.DoublePercentage,
        ReportResultItemValueDisplayType.Integer,
      ].some((item) => item === column.type)

      const columnAlignment = isNumeric ? 'right' : 'left'

      return {
        field: column.key,
        flex: 1,
        disableColumnMenu: true,
        headerAlign: columnAlignment,
        align: columnAlignment,
        cellClassName: isNumeric ? 'NumericValue' : '',
        headerName: t(`reports:report_keys.${capitalize(column.key)}`),
        valueGetter: (value) => formatTableValue(value, column.type),
      }
    },
    [formatTableValue, t],
  )

  const dataGridColumns: GridColDef<Row>[] = useMemo(() => {
    if (!props.reportResults?.columns) return []

    const columns =
      props.reportResults?.columns?.map((column) => columnMapper(column)) || []

    const lastGroupPropertyColumn = columns.findLast((column) =>
      column.field.includes('group'),
    )

    if (lastGroupPropertyColumn) {
      lastGroupPropertyColumn.cellClassName =
        'MuiDataGrid-cell--withRightBorder'
    }

    if (columns.length > 0) {
      columns.unshift({
        field: 'id',
        headerName: '#',
        disableColumnMenu: true,
        flex: 0.5,
        valueGetter: (value) => {
          if (value === -1) return ''
          return typeof value === 'number' ? value + 1 : value
        },
      })
    }

    return columns
  }, [columnMapper, props.reportResults?.columns])

  const noResultData = useMemo(
    () =>
      props.reportResults?.totals &&
      !Object.values(props.reportResults.totals || {}).some((value) => !!value),

    [props.reportResults?.totals],
  )

  const pinnedRows: GridPinnedRowsProp = useMemo(
    () => ({
      bottom: [{ ...props.reportResults?.totals, id: -1 }],
    }),
    [props.reportResults?.totals],
  )

  const rows = useMemo(() => {
    return (
      props.reportResults?.rows.map((row, index) => ({ ...row, id: index })) ||
      []
    )
  }, [props.reportResults?.rows])

  return (
    //TODO: Calculate table size dynamically
    <Box sx={{ width: '100%', height: '65vh' }}>
      {noResultData && (
        <Paper elevation={0} sx={{ p: 2 }}>
          <Divider sx={{ mb: 2, mx: -2 }} />
          {t('reports:no_report_data', { report: props.reportName })}
        </Paper>
      )}

      <DataGrid
        name="report-table"
        rows={rows}
        pinnedRows={pinnedRows}
        rowCount={props.reportResults?.rows.length || 0}
        columns={dataGridColumns}
        sx={{
          '.MuiDataGrid-row--lastVisible': {
            fontWeight: 'bold',
          },
          mb: 1,
          height: '100%',
        }}
        disableAllFilters
      />
    </Box>
  )
}
