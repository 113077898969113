import { useTranslation } from 'react-i18next'
import { Settings } from '.'
import { FormProvider, useForm } from 'react-hook-form'
import { PageHeader } from '../../../components/page-header'
import { Button, Container } from '@mui/material'
import { CurrencyAndTimezone } from './sections/currency-and-timezone'
import { getDirtyValues } from '../../../utils/dirty-fields'
import { FormRouteLeavingGuard } from '../../../components/form-route-leaving-guard'

export type GeneralSettingsFormContext = Settings

type Props = {
  onSubmit: (
    fieldsToUpdate: Partial<GeneralSettingsFormContext>,
  ) => Promise<Settings | undefined>
  settings: Settings | undefined
  formId: string
}

export const GeneralSettingsForm = ({ onSubmit, settings, formId }: Props) => {
  const { t } = useTranslation(['shared', 'settings'])

  const defaultValues: GeneralSettingsFormContext = {
    currencycode: '',
    timezone: '',
  }

  const formContext = useForm<GeneralSettingsFormContext>({
    defaultValues,
    values: settings,
  })

  const handleSubmit = async (formData: GeneralSettingsFormContext) => {
    try {
      const dirtyFieldsRecord = formContext.formState.dirtyFields
      const fieldsToUpdate = getDirtyValues(dirtyFieldsRecord, formData)

      await onSubmit(fieldsToUpdate)
    } catch {
      // Do nothing, handled by onSubmit
    }
  }

  const onFormSubmit = (e: React.FormEvent): void => {
    void formContext.handleSubmit(handleSubmit)(e)
  }

  return (
    <FormProvider {...formContext}>
      <FormRouteLeavingGuard />
      <PageHeader
        title={t('shared:menu.settings')}
        rightColumn={
          <Button
            type="submit"
            disabled={!formContext.formState.isDirty}
            form={formId}
          >
            {t('shared:action.update')}
          </Button>
        }
      />

      <form id={formId} onSubmit={onFormSubmit}>
        <Container>
          <CurrencyAndTimezone />
        </Container>
      </form>
    </FormProvider>
  )
}
